<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div class="px-20 pt-10">

			<a-row :gutter="24" style="margin-top: 0px;">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="">
		
					<a-card class="px-5 pb-0 mb-0">
						<a-row :gutter="24">
							<a-col :span="24" :md="12" :lg="12" :xl="12">
								<h4 class="mt-3 mb-0">
									<a-avatar 
										:size="90" 
										:src="quiz.thumbnail != null ? 
											quiz.thumbnail : 
											'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" 
										alt=""/>
										&nbsp;&nbsp;
									{{ quiz.title }}
								</h4>
							</a-col>

							<a-col :span="24" :md="12" :lg="12" :xl="12" class="text-right">

								<a-button
									v-if="quiz.creator != null && userDetails.uuid != null && userDetails.uuid == quiz.creator.uuid"
									type="primary" 
									class="pt-3 pb-3 text-align-center" 
									@click="toEditQuiz"
									style="margin-top: 20px !important;">
									<a-icon type="form" theme="outlined"/>
									Edit Quiz Details
								</a-button>
								
							</a-col>
						</a-row>
					</a-card>
				</a-col>

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="">

					<a-card class="px-20" style="margin-top: 30px">
						<a-row :gutter="24" class="mt-20 card-header">

							<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24">
								<h5>List of Quiz Questions</h5>
							</a-col>

							<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 text-right">
								<a-button v-if="questions != null && questions.length > 0 && !quiz.publish && quiz.creator != null && userDetails.uuid != null && userDetails.uuid == quiz.creator.uuid" id="publishBtn" class="btn btn-white text-silabu mr-20" @click="showPublishModal=true">
									<a-icon type="check" theme="outlined" /> &nbsp;&nbsp; Publish
								</a-button>
								<router-link v-if="quiz.creator != null && userDetails != null && userDetails.uuid == quiz.creator.uuid" :to="`/tutor-quizzes/view/${this.$route.params.uuid}/add-questions`">
									<a-button id="btn" class="btn btn-silabu">
										<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Questions
									</a-button>
								</router-link>
							</a-col>

						</a-row>
					
						<div v-if="!loadingData && message.length == 0">

							<a-row :gutter="24" class="mt-10">

								<a-col class="mb-24" style="height: 100%;" :span="24" :md="24" :lg="24" :xl="24" v-for="(qn, index) in questions" :key="qn.uuid">
									<a-card class=" px-5 h-100">
										<!-- <a class="text-dark" style="text-decoration: none !important;" :href="`/my-classes/view/${this.$route.params.uuid}/quiz/'${this.$route.params.quizUuid}`"> -->
											<a-row :gutter="24">
												<a-col :span="24" :md="24" :lg="24" :xl="24">
													<h6 style="display: inline !important;">
														<span style="">{{ index + 1 }}.&nbsp;&nbsp;&nbsp;</span> 
														<span  id="deltaValue" class="col-oder-2" v-html="quillToHtml(addOps(qn.content))"></span>
													</h6>
												</a-col>
											</a-row>
											
											<a-row :gutter="24">
												<a-col :span="24" :md="24" :lg="24" class="pl-20 ml-20" v-for="(opt, index) in qn.choices" :key="index">
													<span style="display: inline !important;">
													<span style="">{{ getLabel(index) }}.</span> <span  id="deltaValue" class="col-oder-2" v-html="quillToHtml(addOps(opt.content))"></span>&nbsp;&nbsp;&nbsp;
													<a-icon type="check" class="text-success" theme="outlined" v-if="opt.correct" /> </span>
												</a-col>
											</a-row>

											<a-row class="text-right">

												<a-col :span="24">
													<router-link :to="`/tutor-quizzes/view/${$route.params.uuid}/edit-question/${qn.uuid}`">
														<a-button type="text" size="small" id="editBtn" class="text-silabu">
															<a-icon type="form" theme="outlined"/> Edit
														</a-button>
													</router-link>
													&nbsp;&nbsp;
													<a-button @click="onDelete(qn.uuid)" type="text" size="small" id="deleteBtn" class="text-danger" danger><a-icon type="delete" theme="outlined" /> Delete</a-button>
												</a-col>

											</a-row>
										<!-- </a> -->
									</a-card>
								</a-col>

							</a-row>

						
						</div>


						<div v-if="!loadingData && questions.length == 0">

							<a-row type="flex" :gutter="24" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

								<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

									<img src="../../../public/images/no_data.png" alt="" width="70%" style="" class=""> 

									<h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No questions found</h6>
									
								</a-col>

							</a-row>
						</div>
					

						<div v-if="loadingData">

							<a-row type="flex" :gutter="24" justify="space-around" align="middle" class="" style="min-height: 60vh">

								<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

									<a-spin class="text-primary" size="large"/>

								</a-col>

							</a-row>
						</div>
					</a-card>
				</a-col>
			</a-row>
		</div>


		<a-modal v-model="showPublishModal" :footer="null" :header="null" :closable="false">

			<h6 class="text-center" style="font-size: 20px">
				<a-icon type="exclamation-circle" class="text-warning" size="xl" theme="outlined" />
				Confirm Action
			</h6>
			<p class="mt-3">Are you sure you want to publish this quiz?, once you publish, you can not unpublish.</p>

			<a-row :gutter="24" class="mt-10">
				<a-col :span="24" class="text-right">
					<a-button id="publishBtn" class="btn btn-white text-silabu mr-20" @click="showPublishModal=false">
						Cancel
					</a-button>
					<a-button id="btn" class="btn btn-silabu" @click="handlePublishQuiz">
						Yes, Publish
					</a-button>
				</a-col>
			</a-row>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
    var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;

	export default ({
		
		components: {
			DoubleBounce,
		},

		data() {
			return {
				loadingData: false,
                questions: [],
				classUuid: null,
				topicUuid: null,
				quizUuid: null,
				showPublishModal: false,
				message: '',

				userDetails: {},
				institutionDetails: {}, 
				isCreator: false,

				quiz: {
					uuid: this.$route.params.uuid,
					title: null,
					duration: null,
					systemUuid: undefined,
                    levelUuid: undefined,
                    gradeUuid: undefined,
                    subjectUuid: undefined,
					instruction: null,
                    isPaid: false,
					questionsCount: null,
					isPublished: false,
					price: null,
					thumbnail: null,
                    avatarLoading: false,
                    avatarShowModal: false,
					institution: null,
					creator: null,
                    loading: null,
					isCreate: true,
					showModal: false,
				},
			}
				
		},
		async mounted () {
			let breadcrumbs = [
				{ title: 'Quizzes', url: '/tutor-quizzes', isActive: false },
                { title: 'Details', url: `/tutor-quizzes/view/${this.$route.params.uuid}`, isActive: true },
			];
			
			await this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
			this.quizUuid = this.$route.params.uuid;
			this.initializeDetails();
			this.getQuizQuestions();
			this.getQuizDetails()
		},

		methods: {

			removeUser(item) {
				let index = this.users.indexOf(item);
				if (index !== -1) {
					this.users.splice(index, 1);
				}
			},

			addUser() {
				this.users.push({
					first: '',
					last: '',
					id: Date.now(),
				});
			},

			toEditQuiz() {
				this.$router.push(`/tutor-quizzes/view/${this.quiz.uuid}/edit`);
			},


			handleQuestionChange({ quill, html, text }) {
				console.log('editor change!', quill)
				this.questionContent = html
				this.question.name = quill
			},

			handleQuestionChange(value, delta, source, editor) {
				this.question.name = JSON.stringify(delta)
			},


			handleAnswerChange(value, delta, source, editor) {
				this.choice.name = JSON.stringify(delta)
			},


			quillToHtml(deltaOps) {

				const jsonDeltaOps = JSON.parse(deltaOps)

				const delta = jsonDeltaOps.ops

				var cfg = {};

				var converter = new QuillDeltaToHtmlConverter(delta, cfg);

				var html = converter.convert();

				return html;
			},


			addOps(value) {
				let formattedValue = ''
				if(value != null && value.includes('{\"ops\":')) {
					formattedValue = value;
				}else {
					let defaultStarter = '{\"ops\":';
					let defaultFinisher = '}';

					formattedValue = `${defaultStarter}${value}${defaultFinisher}`;
				}

				return formattedValue;
			},


			removeOps(value) {

				let trimmedValue = ''

				if(value != null && value.includes('{\"ops\":')) {

					let value2 = value.replace('{\"ops\":', '')

					trimmedValue = value2.substring(0, value2.length - 1);
					
				}else{
					trimmedValue = value;
				}

				return trimmedValue
			},


			async initializeDetails() {
				const userDetails = JSON.parse(await localStorage.getItem('user_details'));

				this.userDetails = userDetails;
				
			},



			getLabel(index) {
                if(index == '0') {
                    return 'a';
                }else if(index == '1') {
                    return 'b';
                }else if(index == '2') {
                    return 'c';
                }else if(index == '3') {
                    return 'd';
                }else if(index == '4') {
                    return 'e';
                }else if(index == '5') {
                    return 'f';
                }else if(index == '6') {
                    return 'g';
                }else if(index == '7') {
                    return 'h';
                }else {
                    return 'a';
                }
            },
			

			launchModal() {
				this.question.showModal = true;
			},

			async getQuizQuestions() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/quizzes/questions?quiz=${this.$route.params.uuid}&orderBy=position&order=ASC`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200)  {

						if(response.data.data.length > 0) {
							this.questions = response.data.data;

							// await localStorage.setItem('questionPosition', response.data.length + 1)

						}else{
							this.message = 'No questions available'
						}
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getQuizDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/quizzes?uuid=${this.$route.params.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status >= 200 && response.status < 210)  {

						console.log(response.data)

						this.quiz.uuid = this.$route.params.uuid;
						this.quiz.title = response.data.data.title;
						this.quiz.duration = response.data.data.duration;
						this.quiz.systemUuid = undefined;
						this.quiz.levelUuid = undefined;
						this.quiz.gradeUuid = undefined;
						this.quiz.subjectUuid = undefined;
						this.quiz.instruction = response.data.data.instruction;
						this.quiz.isPaid = response.data.data.isPaid;
						this.quiz.questionsCount = response.data.data.questionsCount;
						this.quiz.isPublished = response.data.data.isPublished;
						this.quiz.price = response.data.data.price;
						this.quiz.thumbnail = response.data.data.thumbnail;
						this.quiz.creator = response.data.data.creator;
						this.quiz.loading = false;

                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

			async onDelete(uuid) {

				let token = await localStorage.getItem("user_token")

				let url = `${this.$BACKEND_URL}/quizzes/questions/${uuid}`;

				this.$AXIOS.delete(url).then(async(response) => {
					
					this.notify('Question was deleted successfully', 'success')
					// this.loadingData = false;
					this.getQuizQuestions();

				}).catch(async(error) => {
					
					// this.loadingData = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

			async handlePublishQuiz(e) {

				e.preventDefault();

				let url = `${this.$BACKEND_URL}/quizzes/${this.$route.params.uuid}`;

				this.showPublishModal = false;

				this.$AXIOS.patch(url, {isPublished: true}).then(async(response) => {
					
					this.notify('Quiz has been successfully published', 'success')

					this.getQuizDetails()

				}).catch(async(error) => {
					
					// this.loadingData = false;
					this.notify('Something went wrong, please try again!!!', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#publishBtn, #publishBtn:hover, #publishBtn:focus { 
	background-color: inherit !important;
	border-color: #734EBF;
	color: #734EBF;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}


p, #deltaValue, #deltaValue > p {
    display: inline-block !important;
}
/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>